import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import SEO from "../components/seo"
const IndexPage = ({ children, location }) => {
  return <>
    <SEO title="Home" />
  </>
}



export default IndexPage
